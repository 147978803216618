import HttpMethod from "../HttpMethod";

const GetTablesRequest = () => {
  const baseUrl = "http://192.168.1.5:3000";
  const url = baseUrl + "/api/Table";
  const method = HttpMethod.GET;
  const body = null;
  return { url, method, body };
};

export default GetTablesRequest;
