import React from "react";
import fetchRequest from "../../network/fetchRequest";
import useRequest from "../../network/useRequest";

const PostList = () => {
  const apiUrl = "https://jsonplaceholder.typicode.com/posts"; // Example API URL
  const request = {
    url: apiUrl,
    method: "get",
  };
  const { data, isLoading, error } = useRequest(request);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <div>
      <h2>Post List</h2>
      <ul>
        {data.map((post) => (
          <li key={post.id}>{post.title}</li>
        ))}
      </ul>
    </div>
  );
};

const Login = ({ onLogin }) => {
  const handleLogin = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObject = {};

    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    const baseUrl = "http://192.168.1.5:3000";

    const url = baseUrl + event.target.target;
    const method = event.target.method;

    const formDataJSON = JSON.stringify(formDataObject);
    const request = {
      body: formDataJSON,
      url: url,
      method: method,
    };
    const { result, error } = await fetchRequest(request);
    if (error) {
    } else {
      if (result.success && result.data.token != null) {
        onLogin(result.data.token);
      }
    }
    // const request = LoginRequest("username", "password");
  };
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Log In</h2>
        <form onSubmit={handleLogin} target="/api/Auth/SignIn" method="POST">
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-600">
              Username
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full px-3 py-2 border rounded border-gray-300 focus:outline-none focus:border-blue-500"
              placeholder="Your username"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-600">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="w-full px-3 py-2 border rounded border-gray-300 focus:outline-none focus:border-blue-500"
              placeholder="Your password"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          >
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
