import { useState, useEffect } from "react";
import axios from "axios";

async function fetchAxios(request) {
  var response = null;
  switch (request.method) {
    case "get":
      response = await axios.get(request.url);
      break;
    case "post":
      response = await axios.post(request.url, request.body);
      break;
    case "put":
      response = await axios.put(request.url, request.body);
      break;
    case "patch":
      response = await axios.patch(request.url, request.body);
      break;

    default:
      break;
  }
  return response;
}

const useRequest = (request) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAxios(request);
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [request.url]);

  return { data, isLoading, error };
};

export default useRequest;
