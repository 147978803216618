import React from "react";

function UserCard(props) {
  return (
    <div className="flex flex-col bg-white rounded-lg overflow-hidden relative">
      <div className="flex items-center grow relative mt-2 mr-3.5 mb-2 ml-4">
        <div className="flex items-center shrink-0 basis-[255px] relative mt-[5px] mb-1">
          <div
            className="flex flex-col bg-[image:var(--src)] bg-[center_center] bg-100% 100% bg-no-repeat rounded-3xl overflow-hidden shrink-0 basis-12 relative min-h-[48px]"
            style={
              {
                //"--src": `url(${require("assets/91f44b7e70a66abf00f1060e1f114a40.png")})`,
              }
            }
          />
          <div className="flex flex-col min-w-[207px]">
            <div className="flex flex-col gap-y-px grow relative mt-[7px] mr-[67px] mb-1 ml-2">
              <div className="font-medium text-[12px] leading-normal font-Poppins text-[rgb(44,62,80)] mr-[15px]">
                Samantha
              </div>
              <div className="font-medium text-[12px] leading-normal font-Poppins text-[rgb(156,161,170)] tracking-[0.12px]">
                +90 (543) 239 51 35
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col shrink-0 basis-[111px] relative">
          <div className="flex flex-col grow relative">
            <div className="w-[62.16%] flex items-center relative ml-[37.84%]">
              <div className="font-normal text-sm font-Montserrat text-[rgb(128,139,150)] shrink-0 basis-[51px] my-1">
                orders:
              </div>
              <div className="flex flex-col min-w-[18px]">
                <h3 className="font-medium text-xl font-Montserrat text-[rgb(128,139,150)] grow ml-1">
                  5
                </h3>
              </div>
            </div>

            <hr
              className="bg-[rgb(128,139,150)] rounded-[0.5px] h-px min-h-[1px] mr-0.5"
              size={1}
            />

            <div className="flex items-center relative mr-0.5 ml-1">
              <div className="font-normal text-sm font-Montserrat text-[rgb(128,139,150)] shrink-0 basis-[57px] my-1">
                income:
              </div>
              <div className="flex flex-col min-w-[48px]">
                <h3 className="font-semibold text-xl font-Montserrat text-[rgb(128,139,150)] tracking-[-0.6px] grow ml-1">
                  $ 98
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCard;
