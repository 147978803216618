import axios from "axios";

async function fetchAxios(request) {
  var response = null;

  const headers = {
    "Content-Type": "application/json",
  };

  switch (request.method) {
    case "get":
      response = await axios.get(request.url, { headers });
      break;
    case "post":
      response = await axios.post(request.url, request.body, { headers });
      break;
    case "put":
      response = await axios.put(request.url, request.body, { headers });
      break;
    case "patch":
      response = await axios.patch(request.url, request.body, { headers });
      break;

    default:
      break;
  }
  return response;
}

async function fetchRequest(request) {
  var result = null;
  var error = null;
  try {
    const response = await fetchAxios(request);
    result = response.data;
  } catch (error) {
    error = error;
  }

  return { result, error };
}

export default fetchRequest;
