import React from "react";

const TableListHeader = () => {
  return (
    <div className="flex flex-col bg-white rounded-lg outline outline-[rgb(200,203,208)] outline-1 outline-offset-[-1px] relative">
      <div className="flex items-center gap-x-4 grow relative mt-[17px] mx-4 mb-4">
        <div className="bg-white rounded-sm outline outline-[rgb(200,203,208)] outline-1 outline-offset-[-1px] shrink-0 basis-[13px] min-h-[13px] my-px" />
        <div className="flex-1 font-semibold text-[12px] leading-tight font-Montserrat text-[rgb(44,61,79)] shrink-0">
          User
        </div>
        <div className="flex-1 font-semibold text-[12px] leading-tight font-Montserrat text-[rgb(44,61,79)] shrink-0 ">
          Car Comfort
        </div>
        <div className="flex-1 font-semibold text-[12px] leading-tight font-Montserrat text-[rgb(44,61,79)] shrink-0 ">
          Ordered Time
        </div>
        <div className="flex-1 font-semibold text-[12px] leading-tight font-Montserrat text-[rgb(44,61,79)] shrink-0 ">
          Start location
        </div>
        <div className="flex-1 font-semibold text-[12px] leading-tight font-Montserrat text-[rgb(44,61,79)] shrink-0 ">
          Start location
        </div>
        <div className="flex-1 font-semibold text-[12px] leading-tight font-Montserrat text-[rgb(44,61,79)] shrink-0">
          Branch
        </div>
        <div className="flex-1 font-semibold text-[12px] leading-tight font-Montserrat text-[rgb(44,61,79)] shrink-0">
          Status
        </div>
      </div>
    </div>
  );
};

export default TableListHeader;
