import React from "react";
import TableList from "../../components/utils/TableList/TableList";

function Settings(props) {
  return (
    <div>
      Ayarlar
      <TableList />
    </div>
  );
}

export default Settings;
