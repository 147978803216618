import React from "react";
import BootstrapCol from "react-bootstrap/Col";
import BootstrapContainer from "react-bootstrap/Container";
import BootstrapRow from "react-bootstrap/Row";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

const SidebarButton = (props) => {
  const { label, selected, imageUrl, link, onClick } = props;

  const bgStyle = selected
    ? "flex flex-col bg-white rounded-[4px_0px_0px_4px] relative"
    : "flex flex-col rounded-[12px_0px_0px_12px] relative min-h-[36px]";

  const textStyle = selected
    ? "font-semibold text-[14px] leading-[1.21] font-Montserrat text-[rgb(44,62,80)] grow ml-2"
    : "font-semibold text-[14px] leading-[1.21] font-Montserrat text-white grow ml-2";

  return (
    <Link className={bgStyle} to={link}>
      <button onClick={onClick}>
        <div className="flex items-center grow relative my-2 mx-3">
          <div className="flex flex-col shrink-0 basis-5 relative">
            <img
              className="w-[24px] h-[24px] object-contain grow min-h-[17px] ml-px"
              src={imageUrl}
              alt="alt text"
            />
          </div>

          <div className="flex flex-col text-left min-w-[123px]">
            <div className={textStyle}>{label}</div>
          </div>
        </div>
      </button>
    </Link>
  );
};

export default SidebarButton;
