import React from "react";
import TableList from "../../components/utils/TableList/TableList";

function Payments(props) {
  return (
    <div>
      Ödemeler
      <TableList />
    </div>
  );
}

export default Payments;
