import React from "react";
import TableList from "../../components/utils/TableList/TableList";

function CampaigneMgmt(props) {
  return (
    <div>
      Kampanya
      <TableList />
    </div>
  );
}

export default CampaigneMgmt;
