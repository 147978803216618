import React from "react";

const BottomPagingBar = () => {
  return (
    <div className="flex items-center ml-auto gap-x-3 relative min-h-[28px]">
      <div></div>
      <div className="flex flex-col">
        <div className="font-medium text-[12px] leading-normal font-Poppins text-[rgb(44,62,80)] grow">
          1-2 of items
        </div>
      </div>

      <div className="flex items-center gap-x-1 shrink-0 relative min-h-[28px]">
        <div className="flex flex-col rounded outline outline-[rgb(200,203,208)] items-center outline-1 outline-offset-[-1px] shrink-0 relative w-[28px] h-[28px]">
          <svg
            className="w-[17px] h-[17px] object-contain grow"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="rgb(200,203,208)"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center bg-[rgb(101,139,255)] rounded outline outline-[rgb(200,203,208)] outline-1 outline-offset-[-1px] grow relative w-[28px] h-[28px]">
            <div className="font-medium text-[12px] leading-normal font-Poppins text-white">
              1
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center rounded outline outline-[rgb(200,203,208)] outline-1 outline-offset-[-1px] grow relative w-[28px] h-[28px]">
            <div className="font-medium text-[12px] leading-normal font-Poppins text-[rgb(86,101,115)]">
              2
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col rounded outline outline-currentColor items-center outline-1 outline-offset-[-1px] shrink-0 relative w-[28px] h-[28px]">
            <svg
              className="w-[17px] h-[17px] object-contain grow"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomPagingBar;
