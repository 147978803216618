import React, { createContext, useContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import SignalRService from "./SignalRService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [currentUser, setCurrentUser] = useState({
    if(token) {
      const secretKey = "tombllsecretkey123123ABCDEFghij"; // The same key used for signing the token
      const decodedToken = jwtDecode(token);
      return {
        id: decodedToken.UserId,
        firstName: decodedToken.FirstName,
        lastName: decodedToken.LastName,
        fullName: decodedToken.FirstName + " " + decodedToken.LastName,
        phoneNumber: decodedToken.GsmNumber,
        email: decodedToken.Email,
      };
    },
  });
  useEffect(() => {
    localStorage.setItem("token", token);
    if (token) {
      const secretKey = "tombllsecretkey123123ABCDEFghij"; // The same key used for signing the token
      const decodedToken = jwtDecode(token);
      setCurrentUser({
        id: decodedToken.UserId,
        firstName: decodedToken.FirstName,
        lastName: decodedToken.LastName,
        fullName: decodedToken.FirstName + " " + decodedToken.LastName,
        phoneNumber: decodedToken.GsmNumber,
        email: decodedToken.Email,
      });
    } else {
      setCurrentUser(null);
    }
  }, [token]);

  const [tables, setTables] = useState([]);
  useEffect(() => {
    SignalRService.startConnection();

    SignalRService.onReceived("ReceiveMessage", (data) => {
      // Handle received message
      alert(data);
    });

    SignalRService.onReceived("TableChanges", (data) => {
      // Handle received message
      setTables(data.data);
    });

    return () => {
      SignalRService.connection.stop();
    };
  }, []);

  const setNewTables = (tables) => {
    setTables(tables);
  };

  const setAuthToken = (newToken) => {
    setToken(newToken);
  };

  const clearAuthToken = () => {
    setToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        setAuthToken,
        clearAuthToken,
        currentUser,
        tables,
        setNewTables,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
