import { HubConnectionBuilder } from "@microsoft/signalr";

class SignalRService {
  constructor() {
    this.connection = new HubConnectionBuilder()
      .withUrl("http://192.168.1.5:3000/connection")
      .withAutomaticReconnect()
      .build();
  }

  startConnection = async () => {
    try {
      await this.connection.start();
      console.log("Connection established.");
    } catch (error) {
      console.error("Error while establishing connection:", error);
    }
  };

  createHubProxy = (hubName) => {
    return this.connection.createHubProxy(hubName);
  };

  onReceived = (eventName, callback) => {
    this.connection.on(eventName, callback);
  };

  sendMessage = (user, message) => {
    this.connection.invoke("SendMessage");
  };
}

export default new SignalRService();
