import React, { useState } from "react";

import SidebarButton from "./SidebarButton";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../network/AuthContext";

const Sidebar = () => {
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { currentUser } = useAuth();

  const isSelected = (index) => {
    let tempIndex = SidebarButtons.findIndex((e) => {
      return e.link == location.pathname;
    });
    return tempIndex != -1 ? tempIndex == index : index == selectedIndex;
  };
  return (
    <div className="w-[270px] flex flex-col relative">
      <div className="w-[270px] h-[649px] flex flex-col gap-y-7 relative left-[1px] top-4">
        <div className="flex flex-col w-[76.67%] relative mr-[23.33%]">
          <div className="flex items-center grow relative mx-3">
            <div className="flex flex-col min-w-[48px]">
              <img
                className="w-12 h-12 object-cover grow min-h-[48px]"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png"
                alt="alt text"
              />
            </div>
            <div className="flex flex-col min-w-[135px]">
              <div className="flex flex-col grow relative min-h-[47px] mt-[0.5px] mb-[0.5px] ml-2">
                <div className="flex flex-col grow relative">
                  <h4 className="flex items-center font-semibold text-[18px] leading-[1.94] font-Poppins text-white mr-[29px]">
                    {currentUser.fullName}
                  </h4>
                  <div className="mt-[-15px] flex flex-col">
                    <div className="flex items-center font-normal text-[12px] leading-[2.25] font-Poppins text-[rgb(156,161,170)] grow">
                      {currentUser.phoneNumber}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-y-4 relative">
          <div className="flex flex-col w-[35.19%] relative mr-[64.81%]">
            <div className="font-bold text-[12px] leading-tight font-Montserrat text-[rgba(255,255,255,0.6)] grow mx-3">
              ANA MENU
            </div>
          </div>

          <div className="flex flex-col gap-y-2.5 relative">
            {SidebarButtons.map((button, index) => {
              return (
                <div>
                  <SidebarButton
                    label={button.label}
                    imageUrl={button.icon}
                    selected={isSelected(index)}
                    link={button.link}
                    onClick={() => {
                      setSelectedIndex(index);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const SidebarButtons = [
  {
    label: "Gösterge Paneli",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/dashboard",
  },
  {
    label: "Siparişler",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/orders",
  },
  {
    label: "Masa Yönetimi",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/tables",
  },
  {
    label: "Paket Servis Yönetimi",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/packages",
  },
  {
    label: "Menü Yönetimi",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/menus",
  },
  {
    label: "Kampanya Yönetimi",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/campaignes",
  },
  {
    label: "Şube Yönetimi",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/branches",
  },
  {
    label: "Personel Yönetimi",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/employees",
  },
  {
    label: "Vardiya Yönetimi",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/shifts",
  },
  {
    label: "İstatistik",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/statistics",
  },
  {
    label: "Tahsilat",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/payments",
  },
  {
    label: "Ayarlar",
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png",
    link: "/settings",
  },
];

export default Sidebar;
