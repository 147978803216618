import React from "react";
import BootstrapCol from "react-bootstrap/Col";
import BootstrapContainer from "react-bootstrap/Container";
import BootstrapRow from "react-bootstrap/Row";

import UserCard from "../../widgets/UserCard";

/**
 *
 * @param {
 *
 * operationQ
 * Combine
 * function builder
 * dynamicCallable
 * Opaque Types
 * Swift Argument Parser
 * User-Defined Literals
 * Identifiable Protocol
 * autoclosure
 * Swift Package Manager (SPM) Conditional Target Dependencies
 * The where Clause for Associated Types
 * Swift @inlinable Attribute
 * Asynchronous Sequences (Asenkron Diziler)
 * Continuation Passing Style (-bilinen ama adı bilinmeyen)
 * Bidirectional Collection Index (İki Yönlü Koleksiyon İndeksi)
 * ExpressibleByStringInterpolation
 * Pattern Matching with Regular Expressions (- let pattern = "Swift.*great")
 * Conditional Conformance
 * Type Erasure (Tür Silme)
 * Swift Result Type
 * Swift Enumerated Sequence
 *
 * } props
 * @returns
 */
function Dashboard(props) {
  return (
    <div className="flex flex-col gap-y-8 grow relative m-8">
      <div className="flex items-center relative min-h-[552px]">
        <div className="flex flex-col gap-y-8 shrink-0 basis-[647px] relative">
          <div className="flex flex-col gap-y-3 relative">
            <div className="flex flex-col relative">
              <h2 className="font-bold text-[24px] leading-[1.16] font-Montserrat text-[rgb(44,62,80)] grow">
                Hızlı Erişim
              </h2>
            </div>
          </div>
        </div>

        <div className="basis-8" />

        <BootstrapContainer
          className="shrink-0 basis-[396px] relative mb-3.5"
          as="div"
          fluid="false"
        >
          <BootstrapRow
            className="flex-start gx-3 gy-3"
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            xxl={1}
          >
            <BootstrapCol className="flex">
              <div className="flex items-center grow relative">
                <h2 className="font-bold text-[24px] leading-[1.16] font-Montserrat text-[rgb(44,62,80)] shrink-0 basis-[372px]">
                  Çalışanlar
                </h2>

                <div className="flex flex-col shrink-0 basis-6 relative my-0.5">
                  <img
                    className="w-1.5 h-2.5 object-cover grow min-h-[10px] mt-[7px] mr-2 mb-[7px] ml-2.5"
                    //src={require("assets/b2ed6e21144f27fe6c06d3aca4e2bf56.png")}
                    alt="alt text"
                  />
                </div>
              </div>
            </BootstrapCol>
            <BootstrapCol className="flex">
              <div className="flex flex-col gap-y-3 grow relative">
                <UserCard />
                <UserCard />
                <UserCard />
                <UserCard />
              </div>
            </BootstrapCol>
          </BootstrapRow>
        </BootstrapContainer>
      </div>

      <div className="flex flex-col gap-y-[7px] relative"></div>
    </div>
  );
}

export default Dashboard;
