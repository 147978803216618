import React, { useState, useEffect, useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TableItem from "./TableItem";
import TableList from "../../components/utils/TableList/TableList";
import { useAuth } from "../../network/AuthContext";
import GetTablesRequest from "../../network/Requests/TableService";
import useRequest from "../../network/useRequest";
import SignalRService from "../../network/SignalRService";

const SettingsPopup = ({ onClose, clickedButton }) => {
  const popupRef = useRef(null);
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsClosing(true);
    }
  };

  const [isClosing, setIsClosing] = useState(false);
  const handleClose = () => {
    setIsClosing(true);
  };
  useEffect(() => {
    if (isClosing) {
      const animationDuration = 300; // milliseconds, should match animation duration
      setTimeout(() => {
        onClose();
      }, animationDuration);
    }
  }, [isClosing, onClose]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 bg-opacity-50">
      <div
        ref={popupRef}
        className={`bg-white rounded-lg p-6 relative animate-popup ${
          isClosing ? "animate-popup-closing" : "animate-popup"
        }`}
      >
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          {/* Custom SVG Close Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="red"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="">
          <div>Masa no: {clickedButton}</div>
          <div>Masa durumu:</div>
        </div>
        <div className="flex">
          <div className="">
            <div className="">
              <TableList />
            </div>
            <div className="">
              <button
                onClick={onClose}
                className="mt-4 mr-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Teslim Edildi
              </button>
              <button
                onClick={onClose}
                className="mt-4 mr-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Ödeme Al
              </button>
              <button
                onClick={onClose}
                className="mt-4 mr-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Taşı
              </button>
              <button
                onClick={onClose}
                className="mt-4 mr-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                İptal Et
              </button>
            </div>
          </div>
          <div className="min-w-[200px]">
            <div className="">
              <div className="flex">
                <div className="grow">Toplam</div>
                <div className="">50₺</div>
              </div>
              <div className="flex">
                <div className="grow">Online</div>
                <div className="">50₺</div>
              </div>
              <div className="flex">
                <div className="grow">Kalan Ödeme</div>
                <div className="">50₺</div>
              </div>
            </div>
            <div className="text-right">
              <div>
                <button
                  onClick={onClose}
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  İptal Et
                </button>
              </div>
              <div>
                <button
                  onClick={onClose}
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  İptal Et
                </button>
              </div>
              <div>
                <button
                  onClick={onClose}
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  İptal Et
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function TableMgmt(props) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);

  const openSettings = (buttonData) => {
    setClickedButton(buttonData); // Set the clicked button data
    setIsSettingsOpen(true);
  };

  const closeSettings = () => {
    setIsSettingsOpen(false);
  };

  const [items, setItems] = useState([]);
  const handleDrag = (startIndex, endIndex) => {
    if (startIndex !== endIndex) {
      const newItems = [...items];
      const [draggedItem] = newItems.splice(startIndex, 1);
      newItems.splice(endIndex, 0, draggedItem);
      setItems(newItems);
    }
  };

  const { data, isLoading, error } = useRequest(GetTablesRequest());
  useEffect(() => {
    setItems(data.data ?? []);
  }, [data]);

  return (
    <div className="p-[20px]">
      <button
        onClick={() => {
          SignalRService.sendMessage("user", "message");
        }}
      >
        Test
      </button>
      <div className="text-[20px] font-bold">Ana Salon</div>

      <div className="flex ml-[-10px]">
        <DndProvider backend={HTML5Backend}>
          {items.map((item, index) => (
            <TableItem
              key={item.id}
              id={item.id}
              text={item.tableName}
              index={index}
              onDrag={handleDrag}
              onClick={() => openSettings(item.id)}
            />
          ))}
        </DndProvider>
      </div>

      {isSettingsOpen && (
        <SettingsPopup onClose={closeSettings} clickedButton={clickedButton} />
      )}
    </div>
  );
}

export default TableMgmt;
