import React from "react";
import TableList from "../../components/utils/TableList/TableList";

function PackageMgmt(props) {
  return (
    <div>
      Paket Siparişler
      <TableList />
    </div>
  );
}

export default PackageMgmt;
