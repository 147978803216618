import React from "react";
import TableListHeader from "./TableListHeader";
import TableListDetail from "./TableListDetail";
import BottomPagingBar from "./BottomPagingBar";

function TableList(props) {
  return (
    <div>
      <div className="flex flex-col gap-y-8 grow relative m-8">
        <div className="flex flex-col gap-y-px relative">
          <div className="flex flex-col gap-y-2 relative">
            <TableListHeader />
            <div className="flex flex-col gap-y-2 relative">
              <TableListDetail />
              <TableListDetail />
              <TableListDetail />
              <TableListDetail />
            </div>
          </div>

          <hr className="bg-[rgb(200,203,208)] h-px min-h-[1px]" size={1} />
        </div>
        <BottomPagingBar />
      </div>
    </div>
  );
}
export default TableList;
