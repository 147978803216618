import React from "react";
import TableList from "../../components/utils/TableList/TableList";

function Orders(props) {
  return (
    <div>
      Siprişler
      <TableList />
    </div>
  );
}

export default Orders;
