import React from "react";

const ContentHeader = () => {
  return (
    <div className="flex flex-col relative min-h-[60px]">
      <div className="flex flex-col gap-y-[19px] grow relative">
        <div className="flex items-center justify-between relative min-h-[40px]">
          <div className="flex items-center shrink-0 basis-[1035px] relative my-1">
            <div className="flex flex-col min-w-[24px]">
              <img
                className="w-6 h-6 object-cover grow min-h-[24px] my-1"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png"
                alt="alt text"
              />
            </div>
            <div className="basis-6" />

            <div className="flex items-center gap-x-[13px] shrink-0 basis-[987px] relative">
              <h1 className="font-medium text-[28px] leading-[1.14] font-Montserrat text-[rgb(44,62,80)] shrink-0 basis-52">
                Good morning
              </h1>
              <h5 className="shrink-0 basis-[203px] my-1.5">
                <span className="font-normal text-[16px] leading-tight font-Montserrat text-black">
                  <span className="text-[rgb(44,62,80)]">you have </span>
                  <span className="text-[rgb(100,139,255)]">1 new message</span>
                </span>
              </h5>
            </div>
          </div>

          <div className="flex flex-col min-w-[40px]">
            <img
              className="w-10 h-10 object-cover grow min-h-[40px]"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png"
              alt="alt text"
            />
          </div>
        </div>
        <hr className="bg-[rgb(247,249,252)] h-px min-h-[1px]" size={1} />
      </div>
    </div>
  );
};
export default ContentHeader;
