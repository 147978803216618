import React from "react";
import TableList from "../../components/utils/TableList/TableList";

function ShiftMgmt(props) {
  return (
    <div>
      Vardiya
      <TableList />
    </div>
  );
}

export default ShiftMgmt;
