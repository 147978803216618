import React, { useState, useEffect } from "react";
import "./Tailwind.css";
import "./App.css";
import { Header, Sidebar, Content } from "./components";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Login from "./pages/Login/Login";
import { AuthProvider, useAuth } from "./network/AuthContext";

const MainApp = () => {
  const { token, setAuthToken, currentUser } = useAuth();

  // Check if a token exists to determine if the user is authenticated
  const isAuthenticated = !!token;
  return isAuthenticated ? (
    // Render Dashboard component when logged in
    <Router>
      <div className="flex items-top gap-x-px relative p-4 pt-8 pb-8 min-h-[100vh]">
        <Sidebar />
        <Content />
      </div>
    </Router>
  ) : (
    // Render Home component when not logged in
    <Login
      onLogin={(newToken) => {
        setAuthToken(newToken);
      }}
    />
  );
};

const App = () => {
  return (
    <AuthProvider>
      <MainApp />
    </AuthProvider>
  );
};

export default App;
