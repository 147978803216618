import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./Content.css";

import ContentHeader from "./ContentHeader";

import {
  Dashboard,
  Orders,
  TableMgmt,
  PackageMgmt,
  MenuMgmt,
  CampaigneMgmt,
  BranchMgmt,
  EmployeeMgmt,
  ShiftMgmt,
  Statistics,
  Payments,
  Settings,
} from "../../pages";

function Content(props) {
  return (
    <div className="flex flex-col bg-white rounded-2xl grow right-4 top-4 bottom-40">
      <div className="flex flex-col gap-y-8 relative m-8">
        <ContentHeader />
      </div>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/tables" element={<TableMgmt />} />
        <Route path="/packages" element={<PackageMgmt />} />
        <Route path="/menus" element={<MenuMgmt />} />
        <Route path="/campaignes" element={<CampaigneMgmt />} />
        <Route path="/branches" element={<BranchMgmt />} />
        <Route path="/employees" element={<EmployeeMgmt />} />
        <Route path="/shifts" element={<ShiftMgmt />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </div>
  );
}

export default Content;
