import React from "react";

const TableListDetail = () => {
  return (
    <div className="flex flex-col relative">
      <div className="flex items-center gap-x-4 grow relative mx-4">
        <div className="bg-white rounded-sm outline outline-[rgb(200,203,208)] outline-1 outline-offset-[-1px] shrink-0 basis-[13px] min-h-[13px] my-px" />

        <div className="flex-1 flex items-center shrink-0 relative my-[3px]">
          <div className="flex-col overflow-hidden shrink-0 basis-12 relative">
            <img
              className="w-[48px] h-[48px] object-contain"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/1200px-Circle-icons-computer.svg.png"
            />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col gap-y-0.5 grow relative mt-[7px] mb-[3px] ml-2">
              <div className="font-medium text-[12px] leading-normal font-Poppins text-[rgb(44,62,80)] mr-[15px]">
                Samantha
              </div>
              <div className="font-medium text-[12px] leading-normal font-Poppins text-[rgb(156,161,170)] tracking-[0.12px]">
                +90 (543) 239 51 35
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 items-center text-left font-medium text-[12px] leading-normal font-Poppins text-[rgb(44,61,79)] shrink-0">
          convenient
        </div>
        <div className="flex-1 items-center font-medium text-[12px] leading-normal font-Poppins text-[rgb(44,61,79)] shrink-0">
          17.11.2021 12:19{" "}
        </div>
        <div className="flex-1">
          <p className="font-medium text-[12px] leading-normal font-Poppins text-[rgb(44,61,79)] grow basis-[122px]">{`13 Kumarik ko'chasi, Tashkent 100167, Oʻzbekiston`}</p>
        </div>
        <div className="flex-1">
          <p className="font-medium text-[12px] leading-normal font-Poppins text-[rgb(44,61,79)] grow basis-[122px]">{`13 Kumarik ko'chasi, Tashkent 100167, Oʻzbekiston`}</p>
        </div>

        <div className="flex-1 font-medium text-[12px] leading-normal font-Poppins text-[rgb(44,61,79)] shrink-0 my-[18px]">
          Yakkasaroy
        </div>

        <div className="flex-1 flex shrink-0 relative my-[15px]">
          <div className="flex flex-col bg-[rgba(36,193,143,0.098)] items-center justify-center rounded-xl w-[80px] h-[24px] relative">
            <div className="font-medium text-[12px] center-normal font-Poppins text-[rgb(36,193,143)]">
              Active
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableListDetail;
