import React from "react";
import TableList from "../../components/utils/TableList/TableList";

function BranchMgmt(props) {
  return (
    <div>
      Şube Yönetimi
      <TableList />
    </div>
  );
}

export default BranchMgmt;
