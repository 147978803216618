import React from "react";
import { useDrag, useDrop } from "react-dnd";

const TableItem = ({ id, text, index, onDrag, onClick }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "ITEM_TYPE",
    item: { id, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "ITEM_TYPE",
    hover(item) {
      if (item.index !== index) {
        onDrag(item.index, index);
        item.index = index;
      }
    },
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <button
      ref={(node) => drag(drop(node))}
      onClick={onClick}
      className="bg-success m-[10px] basis-[87px] py-[10px] opacity-[{opacity}] text-center rounded-[4px] text-[14px] font-regular text-white"
    >
      {text}
    </button>
  );
};

export default TableItem;
