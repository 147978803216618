import React from "react";
import TableList from "../../components/utils/TableList/TableList";

function EmployeeMgmt(props) {
  return (
    <div>
      Personel
      <TableList />
    </div>
  );
}

export default EmployeeMgmt;
